<template>
  <Table
    :table-cols="tableCols"
    :table-data="tableData"
    :show-pagination="false"
  >
    <template v-slot="{ col, row }">
      <div v-if="col.prop === 'goodsBillType'" class="order-type">
        <span>
          {{ $CONSTANT.goodsTypeMap.O[row.type]||'-' }}
        </span>
        <span v-if="+row.type===2" class="time">
          {{ row.extMap.bidExpireTime|parseTime }}
        </span>
      </div>
      <div v-if="col.prop === 'lineName'">
        <TextToolTip :text="`${row.loadAddrName||'-'}-${row.unloadAddrName||'-'}`" placement="none" />
      </div>
      <div v-if="col.prop === 'price'">
        <span v-if="+row.type===1">
          {{ $parseNumber(row.price) }}{{ col.rulesObj[+row.priceUnit]||'-' }}
        </span>
        <span v-else>-</span>
      </div>
      <div v-if="col.prop === 'needTax'">
        {{ row.extMap?col.rulesObj[+row.extMap.needTax]||'-':'-' }}
      </div>
      <div v-if="col.prop === 'plan'" class="plan-time">
        <div>装:<span>{{ row.loadEarlyTime|parseTime }}</span></div>
        <div>卸:<span>{{ row.unloadEarlyTime|parseTime }}</span></div>
      </div>
      <div v-if="col.prop === 'action'" class="flex action-wrap main-c">
        <div v-if="+row.type===1" @click="toDetail(row)">承接</div>
        <template v-else>
          <!-- 竞价比对竞价截止时间和当前时间 -->
          <div v-if="row.extMap.bidExpireTime>nowTime" @click="toDetail(row)">
            承接
          </div>
        </template>
      </div>
    </template>
  </Table>
</template>

<script>
import { parseTime } from '@/utils';
export default {
  filters: {
    parseTime,
  },
  props: {
    tableData: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      nowTime: new Date().getTime(),
      tableCols: [
        { label: '货单编号', prop: 'goodsSerial', fixed: 'left', type: 'jump', evt: (data) => { this.toDetail(data); }, width: '185' },
        { label: '货单类型', prop: 'source', type: 'transition', rulesObj: this.$CONSTANT.goodsSourceMap.O, width: '80' },
        { label: '报车类型', prop: 'goodsBillType', type: 'customize', width: '125' },
        { label: '发单时间', prop: 'createTime', type: 'filterTime', width: '165' },
        { label: '发单人', type: 'userInfoCard', width: 165 },
        { label: '线路名称', type: 'customize', prop: 'lineName', width: '155' },
        { label: '产品名称', prop: 'goodsName' },
        { label: '运输数量(吨)', prop: 'total', type: 'numberTransition' }, // total
        { label: '需求车数', prop: 'truckNum', width: '80' },
        { label: '货主运价', prop: 'price', type: 'customize', rulesObj: this.$CONSTANT.transportPriceUnitMap.O },
        { label: '是否含税', type: 'customize', prop: 'needTax', rulesObj: this.$CONSTANT.taxMap, width: '80' },
        { label: '装卸计划', prop: 'plan', type: 'customize', width: '160' },
        // { label: '操作', type: 'operation', fixed: 'right', align: 'left',
        //   btns: [
        //     {
        //       label: '承接',
        //       evt: (data) => {
        //         this.toDetail(data);
        //       },
        //     },
        //   ],
        // },
        { label: '操作', prop: 'action', type: 'customize', fixed: 'right', align: 'left',
        },
      ],
    };
  },
  watch: {
    tableData(val) {
      this.nowTime = new Date().getTime();
    },
  },
  methods: {
    toDetail(data) {
      this.$router.push(`/goodsOrder/supplyTask/detail?id=${data.id}&status=1&goodsBillType=${data.type || 1}`);
    },
  },
};

</script>
<style lang='scss' scoped>
</style>
