<template>
  <Table
    :table-cols="tableCols"
    :table-data="tableData"
    :show-pagination="false"
  >
    <template v-slot="{ col, row }">
      <div v-if="col.prop === 'goodsBillType'" class="order-type">
        <span>
          {{ $CONSTANT.goodsTypeMap.O[row.type]||'-' }}
        </span>
        <span v-if="+row.type===2" class="time">
          {{ row.extMap.bidExpireTime|parseTime }}
        </span>
      </div>
      <div v-if="col.prop === 'isPushPlatForm'" class="flex-c">
        <el-switch :value="row.isPushPlatForm" size="small" style="zoom: .78" :active-value="1" active-color="#30B08F" inactive-color="#C0C4CC" :inactive-value="0" :disabled="row.isPushPlatForm===1" @change="pushPlatForm(row,'push')" />
        <div v-if="row.isPushPlatForm" class="fz_12 flex-c"><span class="ml5 mr5">{{ row.updateTime|parseTime }}</span><span class="main-c-cursor" @click="pushPlatForm(row,'update')">更新</span></div>
      </div>
      <div v-if="col.prop === 'lineName'">
        <TextToolTip :text="`${row.loadAddrName||'-'}-${row.unloadAddrName||'-'}`" placement="none" />
      </div>
      <div v-if="col.prop === 'price'">
        <span v-if="+row.type===1">
          {{ $parseNumber(row.price) }}{{ col.rulesObj[+row.priceUnit]||'-' }}
        </span>
        <span v-else>-</span>
      </div>
      <div v-if="col.prop === 'needTax'">
        {{ row.extMap?col.rulesObj[+row.extMap.needTax]||'-':'-' }}
      </div>
      <div v-if="col.prop === 'plan'" class="plan-time">
        <div>装:<span>{{ row.loadEarlyTime|parseTime }}</span></div>
        <div>卸:<span>{{ row.unloadEarlyTime|parseTime }}</span></div>
      </div>

      <div v-if="col.prop === 'carCount'">
        <span>
          {{ row.carCount }}
        </span>
      </div>
      <div v-if="col.prop === 'priority'">
        <span :class="col.rulesColorObj[row.priority]">
          {{ col.rulesObj[row.priority] }}
        </span>
      </div>
      <div v-if="col.prop === 'action'" class="flex action-wrap main-c">
        <div v-if="+row.isPushXw!==1" @click="toDetail(row)">推送</div>
        <div v-else @click="reportToOwner(row)">上报货主</div>
      </div>
    </template>
  </Table>
</template>

<script>
import { parseTime } from '@/utils';
import supplyTask from '@/api/goodsOrder/supplyTask';
export default {
  filters: {
    parseTime,
  },
  props: {
    tableData: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      tableCols: [
        { label: '货单编号', prop: 'goodsSerial', fixed: 'left', type: 'jump', evt: (data) => { this.toDetail(data); }, width: '185' },
        { label: '货单类型', prop: 'source', type: 'transition', rulesObj: this.$CONSTANT.goodsSourceMap.O, width: '80' },
        { label: '报车类型', prop: 'goodsBillType', type: 'customize', width: '125' },
        { label: '发单时间', prop: 'createTime', type: 'filterTime', width: '165' },
        { label: '货主确认状态', prop: 'state', type: 'transition', rulesObj: { 1: '待确认', 2: '待确认', 4: '已确认', 5: '已确认', 6: '已确认', 7: '已确认', 8: '已确认', 9: '已确认' }},
        { label: '推送小五', prop: 'isPushXw', type: 'transition', rulesObj: { 0: '未推送', 1: '已推送', null: '未推送' }},
        { label: '推送平台优选', prop: 'isPushPlatForm', type: 'customize', width: '190' },
        { label: '上报车数', prop: 'carCount', width: '80', type: 'customize' },
        { label: '优先级', prop: 'priority', type: 'customize', rulesObj: this.$CONSTANT.priorityMap, rulesColorObj: this.$CONSTANT.priorityColorMap, width: '80' },
        { label: '发单人', type: 'userInfoCard', width: 165 },
        // { label: '企业名称', prop: 'customerName', width: '155' }, // 企业名称（货主直发显示的是货主名称、车队外协显示的是车队名称、卸结转单显示的是货主名称）
        { label: '线路名称', type: 'customize', prop: 'lineName', width: '155' },
        { label: '产品名称', prop: 'goodsName' },
        { label: '运输数量(吨)', prop: 'total', type: 'numberTransition' },
        { label: '货主运价', prop: 'price', type: 'customize', rulesObj: this.$CONSTANT.transportPriceUnitMap.O },
        { label: '是否含税', type: 'customize', prop: 'needTax', rulesObj: this.$CONSTANT.taxMap, width: '80' },
        { label: '需求车数', prop: 'truckNum', width: '100' },
        { label: '装卸计划', prop: 'plan', type: 'customize', width: '160' },
        { label: '操作', prop: 'action', type: 'customize', fixed: 'right', align: 'left',
        },
      ],
    };
  },
  methods: {
    // 推送到平台优选
    pushPlatForm(data, type) {
      if (data.isPushPlatForm && type === 'push') {
        return;
      }
      this.$confirm(`是否确定${type === 'update' ? '更新' : ''}推送到平台优选？`, {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => { // 确认操作
        supplyTask.orderGoodsPushPlatform({ id: data.id }).then(res => {
          if (res) {
            this.$baseMessage(`${type === 'update' ? '更新' : '推送'}成功`, 'success');
            this.$emit('refresh');
          } else {
            this.$baseMessage(`${type === 'update' ? '更新' : '推送'}失败`, 'error');
          }
        });
      });
    },
    toDetail(data) {
      this.$router.push(`/goodsOrder/supplyTask/detail?id=${data.id}&status=2&isSend=${data.isPushXw || 0}`);
    },
    // 上报货主
    reportToOwner(data) {
      this.$router.push(`/goodsOrder/supplyTask/detail?id=${data.id}&status=2&isSend=${data.isPushXw || 0}&tabIndex=1`);
    },
  },
};

</script>
<style lang='scss' scoped>
</style>
